<script setup lang="ts">
const runtimeConfig = useRuntimeConfig()
useHead({
  title: 'Elysium Tour',
  link: [
    { rel: 'icon', type: 'image/x-icon', href: '/favicon.png' }
  ],
  meta: [
    {
      name: 'version',
      content: runtimeConfig.public.lastCommitSha
    }
  ]
})
</script>

<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
