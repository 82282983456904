// plugins/vuetify.js
import { createVuetify } from 'vuetify'
// import colors from 'vuetify/lib/util/colors'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

export default defineNuxtPlugin((nuxtApp) => {
  const vuetify = createVuetify({
    components,
    directives,
    theme: {
      themes: {
        light: {
          dark: false,
          colors: {
            primary: '#0D80A0',
            secondary: '#FF39F0',
            textprimary: '#000000DE',
            textsecondary: '#00000099',
            input: '#E8F9FF',
            error: '#BA1A1A'
          }
        }
      }
    }
  })
  nuxtApp.vueApp.use(vuetify)
})
