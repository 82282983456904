import { default as _91slug_93DqRbx6WLwdMeta } from "/home/runner/work/Guest-Web/Guest-Web/pages/[slug].vue?macro=true";
import { default as indexoJeCHxAeMLMeta } from "/home/runner/work/Guest-Web/Guest-Web/pages/index.vue?macro=true";
export default [
  {
    name: _91slug_93DqRbx6WLwdMeta?.name ?? "slug",
    path: _91slug_93DqRbx6WLwdMeta?.path ?? "/:slug()",
    meta: _91slug_93DqRbx6WLwdMeta || {},
    alias: _91slug_93DqRbx6WLwdMeta?.alias || [],
    redirect: _91slug_93DqRbx6WLwdMeta?.redirect,
    component: () => import("/home/runner/work/Guest-Web/Guest-Web/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexoJeCHxAeMLMeta?.name ?? "index",
    path: indexoJeCHxAeMLMeta?.path ?? "/",
    meta: indexoJeCHxAeMLMeta || {},
    alias: indexoJeCHxAeMLMeta?.alias || [],
    redirect: indexoJeCHxAeMLMeta?.redirect,
    component: () => import("/home/runner/work/Guest-Web/Guest-Web/pages/index.vue").then(m => m.default || m)
  }
]